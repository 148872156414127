

import { mapActions } from "vuex";
// import * as types from "../../vuex/types";
import axios from 'axios';
export default {
  name: "WorkingCapitalLandingPageFilter",
  data() {
    return {
      qualified: true,
      current_step: 1,
      max_step: 8,
      credit_score: '',
      street_address: '',
      street_address_2: '',
      city: '',
      state: '',
      zip_code: '',
      purchase_date: null,
      purchase_price: '',
      current_value: '',
      mortgage_balance: '',
      monthy_payment: '',
      taxes_insurance: '',
      monthy_income: '',
      square_footage: '',
      land_area: '',
      loan_benefit: '',
      loan_purpose: null,
      loan_purpose_options: [
        { value: null, text: '<-- Please Select -->' },
        { value: 'Equipment', text: 'Equipment' },
        { value: 'Real estate', text: 'Real estate' }
      ],
      equipment_type: null,
      equipment_type_options: [
        { value: null, text: '<-- Please Select Equipment Type-->' },
        { value: 'Agriculture', text: 'Agriculture' },
        { value: 'Air Transportation', text: 'Air Transportation' },
        { value: 'Automotive', text: 'Automotive' },
        { value: 'Building Automation', text: 'Building Automation' },
        { value: 'Commercial', text: 'Commercial' },
        { value: 'Construction', text: 'Construction' },
        { value: 'Fitness & Spa', text: 'Fitness & Spa' },
        { value: 'Food Service', text: 'Food Service' },
        { value: 'Intermodal', text: 'Intermodal' },
        { value: 'Landscaping', text: 'Landscaping' },
        { value: 'Logging', text: 'Logging' },
        { value: 'Manufacturing', text: 'Manufacturing' },
        { value: 'Marine Transportation', text: 'Marine Transportation' },
        { value: 'Material Handling', text: 'Material Handling' },
        { value: 'Medical', text: 'Medical' },
        { value: 'Packaging', text: 'Packaging' },
        { value: 'Printing', text: 'Printing' },
        { value: 'Rail Transportation', text: 'Rail Transportation' },
        { value: 'Software', text: 'Software' },
        { value: 'Technology - A/V', text: 'Technology - A/V' },
        { value: 'Technology - Commercial', text: 'Technology - Commercial' },
        { value: 'Technology - Infrastructure', text: 'Technology - Infrastructure' },
        { value: 'Trailer - OTR', text: 'Trailer - OTR' },
        { value: 'Trailer - Vocational', text: 'Trailer - Vocational' },
        { value: 'Truck - OTR', text: 'Truck - OTR' },
        { value: 'Truck - Vocational', text: 'Truck - Vocational' },
        { value: 'XX Accessories', text: 'XX Accessories' }
      ],
      loan_type: null,
      loan_type_options: [
        { value: null, text: '<-- Please Select Loan Type-->' },
        { value: 'Purchase', text: 'Purchase' },
        { value: 'Refinance', text: 'Refinance' }
      ],
      capital_need: null,
      capital_need_options: [
        { value: null, text: '<-- Please Select -->' },
        { value: '$100,000 - $250,000', text: '$100,000 - $250,000' },
        { value: '$250,000 - $500,000', text: '$250,000 - $500,000' },
        { value: '$500,000 - $1,000,000', text: '$500,000 - $1,000,000' },
        { value: '$1,000,000 - $1,500,000', text: '$1,000,000 - $1,500,000' },
        { value: '$1,500,000 - $2,000,000', text: '$1,500,000 - $2,000,000' },
        { value: '$2,000,000 - $2,500,000', text: '$2,000,000 - $2,500,000' },
        { value: '$2,500,000 - $3,000,000', text: '$2,500,000 - $3,000,000' }
      ],
      when_need: null,
      when_need_options: [
        { value: null, text: '<-- Please Select -->' },
        { value: 'Within a week', text: 'Within a week' },
        { value: 'Within 2 weeks', text: 'Within 2 weeks' },
        { value: 'Within a month', text: 'Within a month'},
        { value: 'Unsure', text: 'Unsure'}
      ],
      business_year: null,
      business_year_options: [
        { value: null, text: '<-- Please Select -->' },
        { value: 'Startup (less than 2 years)', text: 'Startup (less than 2 years)' },
        { value: '2 – 4 years', text: '2 – 4 years' },
        { value: '5 – 9 years', text: '5 – 9 years'},
        { value: '10+ years', text: '10+ years'}
      ],
      annual_revenue: null,
      annual_revenue_options: [
        { value: null, text: '<-- Please Select -->' },
        { value: 'Below $100,000', text: 'Below $100,000' },
        { value: '$100,000 – $200,000', text: '$100,000 – $200,000' },
        { value: '$200,000 – $300,000', text: '$200,000 – $300,000' },
        { value: '$300,000 – $500,000', text: '$300,000 – $500,000' },
        { value: '$500,000 – $1,000,000', text: '$500,000 – $1,000,000' },
        { value: '$1,000,000 – $3,000,000', text: '$1,000,000 – $3,000,000' },
        { value: '$3,000,000 – $5,000,000', text: '$3,000,000 – $5,000,000' },
        { value: '$5,000,000+', text: '$5,000,000+' }
      ],
      why_need: null,
      why_need_options: [
        { value: null, text: '<-- Please Select -->' },
        { value: 'Equipment Purchase', text: 'Equipment Purchase' },
        { value: 'Accounts receivable finance', text: 'Accounts receivable finance' },
        { value: 'Vehicle purchase', text: 'Vehicle purchase' },
        { value: 'Meet payroll', text: 'Meet payroll' },
        { value: 'Inventory', text: 'Inventory' },
        { value: 'Commercial real estate', text: 'Commercial real estate' },
        { value: 'Scaling or expansion', text: 'Scaling or expansion' },
        { value: 'Marketing', text: 'Marketing' },
        { value: 'Location remodel', text: 'Location remodel' },
        { value: 'Debt refinance', text: 'Debt refinance' },
        { value: 'Buy a business', text: 'Buy a business' },
        { value: 'Start a business', text: 'Start a business' },
        { value: 'Other', text: 'Other' }
      ],
      business_name: '',
      first_name: '',
      last_name: '',
      phone: null,
      email: null
    };
  },
  computed: {
    progress: function() {
      return Math.round(100 / this.max_step) * this.current_step;
    },
    emailState: function() {
      if(this.email == null) return false;
      var filter = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if(filter.test(this.email)) {
        return true;
      }
      return false;
    },
    phoneState: function() {
      if(this.phone == null) return false;
      var current = this.phone.replace(/\D/g,'');
      if(current.length < 10) return false;
      return true;
    },
    dateState: function() {
      if(this.purchase_date == null) return false;
      var current = this.purchase_date.replace(/\D/g,'');
      if(current.length < 8) return false;
      return true;
    }
  },
  methods: {
    ...mapActions({
      // setFailMessage: types.SET_FAIL_MESSAGE
    }),
    formatter: function(input) {
      // Strip all characters from the input except digits
      input = input.replace(/\D/g,'');
      
      // Trim the remaining input to ten characters, to preserve phone number format
      input = input.substring(0,10);

      // Based upon the length of the string, we add formatting as necessary
      var size = input.length;
      if(size == 0){
              input = input;
      }else if(size < 4){
              input = '('+input;
      }else if(size < 7){
              input = '('+input.substring(0,3)+') '+input.substring(3,6);
      }else{
              input = '('+input.substring(0,3)+') '+input.substring(3,6)+'-'+input.substring(6,10);
      }
      return input; 
    },
    dateFormatter: function(input) {
      // Strip all characters from the input except digits
      input = input.replace(/\D/g,'');
      
      // Trim the remaining input to ten characters, to preserve phone number format
      input = input.substring(0,8);

      // Based upon the length of the string, we add formatting as necessary
      var size = input.length;
      if(size < 3){
              input = input;
      }else if(size < 5){
              input = input.substring(0,2) + '/' + input.substring(2,4);
      }else{
              input = input.substring(0,2) + '/' + input.substring(2,4) + '/' +input.substring(4,8);
      }
      return input; 
    },
    currencyFormatter: function(input) {
      input = input.replace(/\D/g,'');
      return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
      }).format(input);
    },
    onClickApply: function() {
      if(this.capital_need == null) {
        return this.$fire({
          // title: "Title",
          text: "Please select the capital amount your business needs.",
          type: "warning",
          timer: 3000
        }).then(r => {
          console.log(r.value);
        });
      }
      this.current_step++;
    },
    onClickAddress: function() {
      if(this.street_address == '' || this.city== '' || this.state== '' || this.zip_code== '') {
        return this.$fire({
          // title: "Title",
          text: "Please input complete property address.",
          type: "warning",
          timer: 3000
        }).then(r => {
          console.log(r.value);
        });
      }
      this.current_step++;
    },
    onClickCredit: function(score) {
      if(score == 'Poor(620 or less)') {
        this.qualified = false;
        this.current_step = 10;
        return;
      }
      this.credit_score = score;
      this.current_step++;
    },
    onClickLoanPurpose: function() {
      if(this.loan_purpose == null) {
        return this.$fire({
          // title: "Title",
          text: "Please select your loan purpose.",
          type: "warning",
          timer: 3000
        }).then(r => {
          console.log(r.value);
        });
      }
      if(this.loan_purpose == 'Equipment' && this.equipment_type == null) {
        return this.$fire({
          // title: "Title",
          text: "Please select equipment type.",
          type: "warning",
          timer: 3000
        }).then(r => {
          console.log(r.value);
        });
      }
      if(this.loan_purpose == 'Real estate' && this.loan_type == null) {
        return this.$fire({
          // title: "Title",
          text: "Please select loan type.",
          type: "warning",
          timer: 3000
        }).then(r => {
          console.log(r.value);
        });
      }
      this.current_step++;
    },
    onClickRevenue: function() {
      if(this.annual_revenue == null) {
        return this.$fire({
          // title: "Title",
          text: "Please select your annual revenue.",
          type: "warning",
          timer: 3000
        }).then(r => {
          console.log(r.value);
        });
      }
      if(this.annual_revenue == 'Below $100,000' || this.annual_revenue == '$100,000 – $200,000') {
        this.qualified = false;
        this.current_step = 10;
        return;
      }
      this.current_step++;
    },
    onClickWhy: function() {
      if(this.why_need == null) {
        return this.$fire({
          // title: "Title",
          text: "Please select what you need the financing for.",
          type: "warning",
          timer: 3000
        }).then(r => {
          console.log(r.value);
        });
      }
      this.current_step++;
    },
    onClickWhen: function() {
      if(this.when_need == null) {
        return this.$fire({
          // title: "Title",
          text: "Please select when you need the financing.",
          type: "warning",
          timer: 3000
        }).then(r => {
          console.log(r.value);
        });
      }
      this.current_step++;
    },
    onClickContinue: function(input) {
      if(input == '') {
        return this.$fire({
          // title: "Title",
          text: "Please complete input to continue.",
          type: "warning",
          timer: 3000
        }).then(r => {
          console.log(r.value);
        });
      }
      this.current_step++;
    },
    onClickPurchaseDate: function() {
      if(this.dateState == false) {
        return this.$fire({
          // title: "Title",
          text: "Please input valid date.",
          type: "warning",
          timer: 3000
        }).then(r => {
          console.log(r.value);
        });
      }
      this.current_step++;
    },
    onClickName: function() {
      if(this.first_name == '' || this.last_name== '') {
        return this.$fire({
          // title: "Title",
          text: "Please input your first and last name.",
          type: "warning",
          timer: 3000
        }).then(r => {
          console.log(r.value);
        });
      }
      this.current_step++;
    },
    onClickSubmit: function() {
      if(this.phoneState == false || this.emailState == false) {
        return this.$fire({
          // title: "Title",
          text: "Please input valid phone number and email.",
          type: "warning",
          timer: 3000
        }).then(r => {
          console.log(r.value);
        });
      }
      var leadData = {
        loan_amount: this.capital_need,
        loan_purpose: this.loan_purpose,
        equipment_type: this.equipment_type,
        loan_type: this.loan_type,
        loan_benefit: this.loan_benefit,
        when_need: this.when_need,
        business_name: this.business_name,
        first_name: this.first_name,
        last_name: this.last_name,
        phone_number: this.phone,
        email_address: this.email,
        campaign: this.$route.query.campaign
      };
      //slack notice webhook
      axios.create({ transformRequest: [(data, _headers) => JSON.stringify(data)] })
                  .post('https://hooks.zapier.com/hooks/catch/11204908/2y6nqju/',leadData);
      //hubspot new lead webhook
      axios.create({ transformRequest: [(data, _headers) => JSON.stringify(data)] })
                  .post('https://hooks.zapier.com/hooks/catch/11204908/begzrid/',leadData);
      //send welcome email to FB new lead
      axios.post("https://api.amcredit.com/apply/sendTransactionalEmail", {customerEmail: this.email, firstName: this.first_name, emailTemplate: 'nonprofit-financing-new-lead-welcome', emailSubject: 'Thank You for Your Interest in Municipal/Non-Profit Financing with American Credit!' })
      .then(res => console.log(res))
      .catch(err => console.log(err.message));
      this.current_step++;
    }
  }
};
